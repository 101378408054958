<template>
  <main class="mr-4">
    <trac-back-button>Back</trac-back-button>
    <trac-loading v-if="isLoading" />
    <h3 class="my-8 font-bold text-sm">KYC</h3>

    <div
      class="
        shadow-lg
        rounded-md
        p-4
        pt-8
        md:w-9/12
        mx-auto
        my-8
        md:px-12
        flex flex-col
        items-center
        py-16
      "
    >
      <h3 class="my-5 font-bold text-primaryBlue">Confirm Password</h3>
      <p class="text-xs">
        Please enter your Traction Password for verification
      </p>
      <div class="my-10 w-full md:w-128">
        <trac-input
          v-model.trim="password"
          type="password"
          placeholder="Password"
          specified="password"
          class="mt-6 w-full md:w-128"
          ref="password"
          tag="div"
        />
      </div>

      <trac-button
        :disabled="password.trim() === ''"
        class="w-full max-w-xs my-12"
        @button-clicked="confirmPassword"
        ><div class="py-1">Verify</div></trac-button
      >
    </div>
  </main>
</template>

<script>
import {
  GET_USER_BUSINESS_DATA,
  GET_USER_BUSINESS_ID,
  GET_USER_DATA,
} from "../../../browser-db-config/localStorage";
import { eventBus } from "./../../../main";

export default {
  data() {
    return {
      isLoading: false,
      password: "",
    };
  },
  computed: {
    nextOps() {
      switch (this.$route.query.ops) {
        case "forgot_wallet_pin":
          return "enter_otp";
        case "change_wallet_pin":
          return "old_pin";
        case "change_settlement_account":
          return "change_settlement_account";
        default:
          return "";
      }
    },
  },
  methods: {
    async confirmPassword() {
      this.isLoading = true;
      const res = await this.$store.dispatch("RECHECK_PASSWORD", {
        email: GET_USER_BUSINESS_DATA().email,
        password: this.password,
      });
      if (res.status) {
        if (this.$route.query.ops === "forgot_wallet_pin") {
          await this.sendOTP();
        } else {
          this.gotoNextPage();
        }
      } else {
        eventBus.$emit("trac-alert", {
          message:
            // res.message,
            "Invalid password.",
        });
      }
      this.isLoading = false;
    },
    async sendOTP() {
      // this.isLoading = true;

      const res = await this.$store.dispatch("SEND_WALLET_OTP", {
        businessId: GET_USER_BUSINESS_ID(),
        verificationType: "PHONE",
        reference: GET_USER_BUSINESS_DATA()["phone"],
      });

      if (res.status) {
        this.gotoNextPage();
        // this.$router.push({
        //   name: "ConfirmPassword",
        //   query: {
        //     ops: "enter_otp",
        //   },
        // });
      } else {
        eventBus.$emit("trac-alert", {
          message: res.message || "Error: Couldn't send OTP.",
        });
      }
      // this.isLoading = false;
    },
    gotoNextPage() {
      if (this.$route.query.ops === "change_settlement_account") {
        this.$router.replace({
          name: "AddBankDetails",
          query: {
            ops: this.nextOps,
            onboarding: this.$route.query.onboarding
          },
        });
      } else {
        this.$router.push({
          name: "ConfirmPinInput",
          query: {
            ops: this.nextOps,
          },
        });
      }
    },
  },
};
</script>

<style>
</style>